
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient-bg-1 {
  
    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 73% 72%, #2375FF4A 1%, #A259FF36 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 73% 72%, #2375FF4A 1%, #A259FF36 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 73% 72%, #2375FF4A 1%, #A259FF36 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 73% 72%, #2375FF4A 1%, #A259FF36 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 73% 72%, #2375FF4A 1%, #A259FF36 100%);
}


.gradient-bg-2 {
  /* ff 3.6+ */
  background: -moz-linear-gradient(358deg, #2375FF80 0%, #FFFFFF 100%, #FFFFFF 100%); 

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(358deg, #2375FF80 0%, #FFFFFF 100%, #FFFFFF 100%);

  /* opera 11.10+ */
  background: -o-linear-gradient(358deg, #2375FF80 0%, #FFFFFF 100%, #FFFFFF 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2375FF', endColorstr='#FFFFFF', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(358deg, #2375FF80 0%, #FFFFFF 100%, #FFFFFF 100%);

  /* global 94%+ browsers support */
  background: linear-gradient(358deg, #2375FF80 0%, #FFFFFF 100%, #FFFFFF 100%);
}

.gradient-text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-padding {
  padding-top: 2rem; 
  padding-bottom: 6rem; 
  padding-right: 4rem; 
  padding-left: 4rem; 
}

.section-margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/







@media screen and (max-width: 768px) {
  .sectio-padding {
    padding: 4rem;
  }

  .section-margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 576px) {
  .section-padding {
    padding: 4rem 2rem;
  }

  .section-margin {
    margin: 4rem 2rem;
  }  
}