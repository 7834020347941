.blog-hero-container{
    /* margin-top: 6rem; */
    font-family: var(--font-family);
    /* color: #FFF; */
    /* color: #F5F5F5; */
    color:#9F9F9F;
    padding: 2rem 10rem; 
    /* width: 600px; */
    /* height: 200px; */
}

.blog-hero-image{
    width: 100%;
    height: 500px;
    background-size: cover; 
    /* background-repeat: no-repeat;  */
    background-position: center; 
    overflow: hidden;
   

}


.blog-hero-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 0rem 5rem;
    height: 100%;
}


.blog-hero-title{
    margin: 2rem 0rem 1rem 0rem;
}

.blog-hero-title h3{
    color: #FAC40C;
    font-weight: 300;
}

.blog-hero-title h1{
    text-align: left;
    font-size: 30px;
    font-weight: 500;
    color: #F5F5F5;
    
}


.blog-hero-overview{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
    margin-bottom: 1rem;
}



.blog-hero-authors-detail{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 30px;
}

.blog-hero-authors{
    display: flex;
    justify-content: left;
    align-items: center;
    
}


.blog-hero-author-image img{
    width: 75px;
    height: 75px;
    border-radius: 100%;
}


.blog-author-hero-name{
    font-size: 18px;
    font-weight: 400;
    margin-left: 5px;
}

.blog-hero-date{
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 2rem;
}

/* .blog-article-categories{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;

} */



/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 576px) {

    .blog-hero-container{
        padding: 2rem 4rem;
    }

    .blog-hero-title h3{
        color: #FAC40C;
        font-weight: 200;
    }
    
    .blog-hero-title h1{
        text-align: left;
        font-size: 24px;
        font-weight: 400;
        color: #F5F5F5;
        
    }

    .blog-hero-overview{
        display: none;
    }

    .blog-hero-author-image img{
        width: 50px;
        height: 50px;
        border-radius: 100%;
    }
    
    
    .blog-author-hero-name{
        font-size: 14px;
        font-weight: 300;
        margin-left: 5px;
    }

    .blog-hero-date{
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 2rem;
    }

   
}

@media screen and (max-width: 768px){

    .blog-hero-title h3{
        color: #FAC40C;
        font-weight: 200;
    }

    .blog-hero-title h1{
        text-align: left;
        font-size: 26px;
        font-weight: 400;
        color: #F5F5F5;
        
    }

    .blog-hero-overview{
        display: none;
    }

    .blog-hero-author-image img{
        width: 50px;
        height: 50px;
        border-radius: 100%;
    }
    
    
    .blog-author-hero-name{
        font-size: 14px;
        font-weight: 300;
        margin-left: 5px;
    }


}

/* @media screen and (max-width: 992px)  */
@media screen and (min-width: 769px) and (max-width: 992px) {

    .blog-hero-title h3{
        color: #FAC40C;
        font-weight: 300;
    }
    
    .blog-hero-title h1{
        text-align: left;
        font-size: 26px;
        font-weight: 400;
        color: #F5F5F5;
        
    }
    
    
    .blog-hero-overview{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 200;
        text-align: left;
        margin-bottom: 1rem;
    }
    
    .blog-hero-author-image img{
        width: 60px;
        height: 60px;
        border-radius: 100%;
    }
    
    
    .blog-author-hero-name{
        font-size: 16px;
        font-weight: 200;
        margin-left: 5px;
    }

    .blog-hero-date{
        font-size: 16px;
        font-weight: 200;
        margin-bottom: 2rem;
    }


}
