.cookie-banner a{
    font-weight: normal;
}

.cookie-banner a:hover{
    /* font-weight: bold; */
    text-decoration: underline; 
}

.cookie-banner p{
    color: #F5F5F5;
}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/