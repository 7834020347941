.home-page-container{
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #FFF;
}

.company-logo img{
    width: 300px;
    height: auto;
    padding: 0.5rem 1rem;
}

.company-tagline h1{
    font-size:18px;
    color: #FF4700;
    font-weight: 200;
    letter-spacing: 2.5px;
    margin-bottom: 10rem;
}

.rebuilding-message{
    color: #32324D;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 200;
    padding: 0.5rem 0rem;;
}


.rebuilding-message span{
    color: #FF4700;
    font-weight: 300;
}

.bottom-container-old{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.footer-bottom-old{
    display: flex;
    justify-content: center;
    border-top: .5px outset #9F9F9F;
    border-bottom: .5px outset #9F9F9F;
    padding: 1rem 0rem; 
}

.email-container-old p{
    color: #32324D;
    font-family: var(--font-family);
    font-size: 14px;
    padding: 1rem 0rem;
    cursor: pointer;
}

.email-container-old a:hover{
    text-decoration: underline solid #FF4700 1.5px;
}

.footer-bottom-old p{
    color: #9F9F9F;
    font-family: var(--font-family);
    font-size: 14px;
}


/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 576px) {

    .footer-bottom-old{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


}