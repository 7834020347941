.blog-article-container{
    margin-top: 6rem;
    font-family: var(--font-family);
    color: var(--color-lighttext);
    padding: 2rem 15rem 2rem 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-article-title{
    margin: 2rem 0rem 0.5rem 0rem;
}

.blog-article-title h1{
    /* font-family: var(--font-family); */
    font-size: 30px;
    /* color: #2A2A2A; */
    font-weight: 500;
}


.blog-article-image{
    display: flex;
    justify-content: center;
}

.blog-article-image img{
    width: 800px;
    height: 400px;
    margin-bottom: 2rem;

}

.blog-article-overview{
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-family: var(--font-family); */
    /* color: #4D4D4D; */
    margin-bottom: 2rem;
}

.blog-article-overview{
    font-size: 20px;
    text-align: left;
}

.blog-authors-detail{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    margin-bottom: 2rem;
    
}

.blog-article-authors{
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-author-image img{
    width: 75px;
    height: 75px;
    border-radius: 100%;
}
.blog-author-image img:hover{
    width: 75px;
    height: 75px;
    border-radius: 100%;
    border: 1.5px solid var(--color-secondary);

}

.blog-author-name{
    /* font-family: var(--font-family); */
    font-size: 18px;
    /* color: #4D4D4D; */
    margin-left: 15px;
}

.blog-author-name p:hover {
    text-decoration: underline solid var(--color-secondary) 1.5px; 
}

.blog-article-date{
    /* font-family: var(--font-family); */
    font-size: 18px;
    /* color: #4D4D4D; */
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.blog-article-categories{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;

}

.blog-article-categories button {
    background-color: #FAC40C;
    /* font-family: var(--font-family); */
    font-size: 18px;
    /* color: #4D4D4D; */
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
    padding: .5rem 1rem;
    margin: 4px 4px 4px 4px;
    text-wrap:nowrap
}

.blog-article-categories button:hover {
    filter: brightness(110%);
}

.blog-article-head-section{
 
    width: 100%;
    min-width: 350px;
    margin-bottom: 3rem;
}


.blog-article-section-header{
    font-size: 18px;
    text-align: center;
    /* color: var(--color-primary); */
}

.blog-article-section-header h2{
    font-family: var(--font-family);
    color: var(--color-lighttext);
    font-size: 22px;
    text-align: center;
    font-weight: 600;
} 

.blog-article-sections-button h2{
    font-family: var(--font-family);
    /* color: var(--color-lighttext); */
    /* color: var(--color-primary); */
    font-size: 20px;
    text-align: left;
    font-weight: 300;
}



.blog-article-sections button{
    margin: 5px 0px;
    /* background: var(--color-secondary); */
    background: #FFF;
    /* color: #FFF; */
    color: var(--color-lighttext);
    width: 100%;
    padding: 5px 5px 5px 24px;
    /* background: none; */
    outline: none;
    border: none;
    /* cursor: pointer; */
    /* border-radius: 5px; */
    /* border: 1px solid var(--color-secondary);  */
    border: 1px solid var(--color-lighttext);
}


.blog-article-sections button:hover{
    /* filter: brightness(110%); */
    /* filter: opacity(0.75); */
    /* background: var(--color-secondary); */
    /* color: #FFF; */
    padding: 5px 5px 5px 17px;
    color: var(--color-lighttext);
    outline: none;
    cursor: pointer;
    /* border-radius: 5px; */
    /* border: 1px solid var(--color-secondary);  */
    border-left: 8px solid var(--color-secondary); /* Increase left border thickness */
    border-top: 1px solid var(--color-secondary);
    border-right: 1px solid var(--color-secondary);
    border-bottom: 1px solid var(--color-secondary);
}

.blog-article-body-section{
    text-align: justify;
    margin-bottom: 2rem;
    line-height: 1.8;
    min-width: 350px;

}

.blog-article-body-section h2{
    font-family: var(--font-family);
    color: var(--color-lighttext);
    font-size: 22px;
    text-align: left;
    font-weight: 600;
    
}

.blog-article-body-section p{
    font-size: 18px;
    text-align: left;
}
/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/