.blog-landing-page-container{
    margin-top: 6rem;
    font-family: var(--font-family);
}

h1{
    text-align: center;
}

.blog-article-browse-section{
    margin-top: -6rem;
}