
.contact-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
    background-color: var(--color-bg);
}
.calendly-inline-widget{
    display: flex;
    width:100%;
    height: 700px;
}

.alternate-contact-info{
    font-family: var(--font-family);
    color: #4D4D4D;
    font-size: 16px;
    text-align: center;
    margin: 0rem 0.5rem 0rem 0.5rem;
}

.alternate-contact-info a{
    color: var(--color-primary);
}

.alternate-contact-info a:hover{
    text-decoration: underline solid var(--color-secondary) 1.5px;
}

.contact-title h1{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    border: 1px solid #ddd;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color:#F5F5F5;
}

.full-name,
.email,
.company,
.requirement{
    width: 100%;
    margin-bottom: 1rem;
}

.code-phone{
    margin-bottom: 1rem;
}

/* .company,
.requirement,
.solutions{
    margin-top:-1rem;
} */

.calendly-inline-widget{
    margin-top: 1rem;
}


form label,
form p {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    color: #2A2A2A;
}

form input {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    font-family: var(--font-family); 
    font-size: 12px;
    color: #4D4D4D;
}

form textarea {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    font-family: var(--font-family); 
    font-size: 12px;
    color: #4D4D4D;
}


.enabled-button {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    background: #2375FF;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    /* margin-top: 1rem; */
}

.enabled-button:hover{
    /* background-color: var(--color-button-hover); */
     /* background-color:#A259FF; */
     filter: brightness(110%);
}

.enabled-button:focus{
    outline: none;
    border: 1px solid #4D4D4D; 
}

/* .disabled-button {
    width: 100%;
    padding: 0.5rem 1rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    border: none;
    outline: none;
    border-radius: 5px;
    background: #4D4D4D; 
    cursor:not-allowed;

  }
   */

label span{
    display: inline-flex;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 200;
    color: var(--color-error)

}

.solutions{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
}

.checkbox-container{
    display: flex;
    /* align-items: center; */
    padding-top: 5px;
}


.checkbox-container label{
    /* font-size: 12px;
    font-weight: 300;
    margin-left: 5px; */

    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    color: #2A2A2A;
    margin-left: 5px;

} 

/* input[type="checkbox"]:checked {
    background-color: #FFF;
} */

.checkbox-container-inp {
    display: flex;
}

.checkbox-container-lbl {
    font-weight: 300;
    margin-left: 5px;
}


.code-phone{
    display: flex;
    align-items: center;
    width: 100%;
}

.code {
    min-width: 130px;
    margin-right: 5px;;
    font-family: var(--font-family); 
    font-size: 12px;
    font-weight: 300;
    color: #4D4D4D;
}




.phone{
    width: 100%;
}

.inp-error{
    font-family: var(--font-family); 
    font-size: 12px;
    font-weight: 400;
    color: var(--color-error);
    text-wrap:wrap;

}

.inp-success{
    font-family: var(--font-family); 
    font-size: 12px;
    font-weight: 400;
    color: var(--color-success);
    text-wrap:wrap;

}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px; 
    height: 20px; 
    border-width: 5px;
    border: 0.25px solid #9F9F9F;
    color: #FFF;
    background-color: #FFF;
    border-radius: 3px;
    position: relative; /* Ensure proper positioning of the pseudo-element */
    /* outline: none; Ensure that focus styling is removed */
  }

input[type="checkbox"]:not(:checked) {
    background-color: #FFF;
}

input[type="checkbox"]:checked {
    background-color: var(--color-primary)
}

input[type="checkbox"]:checked::after {
    content: '\2713'; /* Unicode for check mark */
    color: #FFF; /* Color of the check mark */
    font-size: 16px; /* Adjust size as needed */
    position: absolute;
    top: 50%; /* Adjust vertical position */
    left: 50%; /* Adjust horizontal position */
    transform: translate(-50%, -50%); /* Center the check mark */
}

.privacy-policy-acceptance{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 1rem;

}

.error-container{
    width: 60%;
    margin-top: -1.0rem;
    margin-bottom: 1rem;
    text-align: left;
}

/* input[type="text"],
input[type="email"],
input[type="phone"],
input[type="company"]
{
  height: 40px; 
  width: 100%; 
  padding: 8px; 
} */


/* textarea {
  height: 100px; 
  width: 100%; 
  padding: 8px; 
} */



/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 768px){

    .code-phone{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .code{
        width: 100%;
        margin-bottom: 5px;
    }
    
}