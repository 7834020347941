

/* Importing google fonts Poppins  */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500&family=Quicksand:wght@300;400;500;600;700&display=swap');

:root {
  /*Fonts used on website  */
  --font-family: 'Poppins', sans-serif; 

  /*Gradients generated at https://angrytools.com/  */
  --gradient-text: linear-gradient(90deg, #2375FF 0%, #A259FF 100%);
  --gradient-bar: linear-gradient(90deg, #2375FF 0%, #A259FF 100%);

  
  /* Colors used on website */
  --color-bg: #f9f9f9;
  --color-dark-bg: #9F9F9F;
  --color-light-bg: #F5F5F5;
  --color-primary: #2375FF;
  --color-secondary: #A259FF;
  --color-logo-text:#170F49;
  --color-box: #FFFFFF;
  --color-text: #2A2A2A;
  --color-subtext: #4D4D4D;
  --color-lighttext: #32324D;
  --color-warning:#FAC40C;
  --color-myred:#F24C17;
  --color-mygreen: #36FEB0;
  --color-error:#b41212;
  --color-success: #219f7f;
  --color-button-hover: #2361ff;
}



