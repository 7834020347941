
.blog-category-container{
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem;
    
}

.blog-category-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
}

.blog-category-buttons button{
    background-color: #FAC40C;
    font-family: var(--font-family);
    font-size: 18px;
    color: #4D4D4D;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
    padding: .5rem 1rem;
    margin: 4px 4px 4px 4px;

}

.blog-category-buttons button:hover{
    filter: brightness(110%);
}


.category-blogcards{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.category-blogcards-pagination-buttons  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;

}

.category-blogcards-pagination button{
    display: flex;
    align-items: center;  
    justify-content: center;
    background-color: var(--color-primary);
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    margin: 3px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
}

.category-blogcards-pagination button:hover {
    /* background-color: var(--color-button-hover); */
    filter: brightness(110%);
  }

.category-blogcards-pagination-prev button[disabled],
.category-blogcards-pagination-next button[disabled] {
  /* background-color: #4D4D4D; */
  background-color: #9F9F9F;
  cursor: not-allowed;
  filter: brightness(100%);
}

  /* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 576px) {
    .category-blogcards {
        grid-template-columns: repeat(1, 1fr) !important;
      }

}


@media screen and (max-width: 992px){
    .category-blogcards {
        grid-template-columns: repeat(2, 1fr);
      }

}