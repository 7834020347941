.hero {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem 4rem;
    min-height: 600px;
}

.hero-content {
    flex: 1;
    display: flex;
    align-items:flex-start;
    flex-direction: column;
    max-height: 400px;
    padding: 2rem 2rem 0rem 2rem;
}

.hero-content h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    text-align: left;
    
}

.hero-content p {
    font-family: var(--font-family);
    font-weight: 200;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.hero-content-action {
    display:flex;
    flex-direction: row;
    width: 100%;
    margin: 2rem 0 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



.main-action button {
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    background: #2375FF;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 1rem;
}

.main-action button:hover{
    /* background-color: var(--color-button-hover); */
    filter: brightness(110%);
}




.sub-action button {
    padding: 0.5rem 1rem;
    color: #4D4D4D;
    background: #FFFFFF;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    border: 1px solid #4D4D4D;
    cursor: pointer;
    border-radius: 5px;
}


.hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image img {
    width: 600px;
    height: 400px;
}



/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 1200px){

    .hero-content h1 {
        font-weight: 500;
        font-size: 50px;
        line-height: 70px;
        letter-spacing: -0.04em;
        text-align: left;
        
    }

    .hero-content p {
        font-weight: 200;
        font-size: 16px;
        line-height: 24px;
    }



    .hero-image img {
        width: 500px;
        height: 320px;
    }
}

@media screen and (max-width: 992px){
    .hero-image{
        align-items: center;
    }

    .hero {
        flex-direction: column;
    }

    .hero-content {
        margin: 0 0 3rem;
    }

    .hero-content {
        padding: 2rem 0rem 0rem 0rem;
    }
    
}

@media screen and (max-width: 768px) {
    .hero-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .hero-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .hero-content-action button {
        font-size: 16px;
        line-height: 24px;
    }

    .hero-image img {
        width: 325px;
        height: 200px;
    }
}


@media screen and (max-width: 576px) {

    .hero-content{
        text-align: center;
        
    }
   .hero-content-action{
    justify-content: center;
   }

    .hero-content h1 {
        font-size: 30px;
        line-height: 42px;
        text-align: center;
    }

    .hero-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .hero-content-action button {
        font-size: 12px;
        line-height: 16px;
    }

    .hero-image img {
        width: 250px;
        height: 150px;
    }
}






