@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500&family=Quicksand:wght@300;400;500;600;700&display=swap);


/* Importing google fonts Poppins  */

:root {
  /*Fonts used on website  */
  --font-family: 'Poppins', sans-serif; 

  /*Gradients generated at https://angrytools.com/  */
  --gradient-text: linear-gradient(90deg, #2375FF 0%, #A259FF 100%);
  --gradient-bar: linear-gradient(90deg, #2375FF 0%, #A259FF 100%);

  
  /* Colors used on website */
  --color-bg: #f9f9f9;
  --color-dark-bg: #9F9F9F;
  --color-light-bg: #F5F5F5;
  --color-primary: #2375FF;
  --color-secondary: #A259FF;
  --color-logo-text:#170F49;
  --color-box: #FFFFFF;
  --color-text: #2A2A2A;
  --color-subtext: #4D4D4D;
  --color-lighttext: #32324D;
  --color-warning:#FAC40C;
  --color-myred:#F24C17;
  --color-mygreen: #36FEB0;
  --color-error:#b41212;
  --color-success: #219f7f;
  --color-button-hover: #2361ff;
}





* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient-bg-1 {
  
    /* ff 3.6+ */

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 73% 72%, #2375FF4A 1%, #A259FF36 100%);
}


.gradient-bg-2 {
  /* ff 3.6+ */ 

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */
  background: -o-linear-gradient(358deg, #2375FF80 0%, #FFFFFF 100%, #FFFFFF 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2375FF', endColorstr='#FFFFFF', GradientType=1 );

  /* ie 10+ */

  /* global 94%+ browsers support */
  background: linear-gradient(358deg, #2375FF80 0%, #FFFFFF 100%, #FFFFFF 100%);
}

.gradient-text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-padding {
  padding-top: 2rem; 
  padding-bottom: 6rem; 
  padding-right: 4rem; 
  padding-left: 4rem; 
}

.section-margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/







@media screen and (max-width: 768px) {
  .sectio-padding {
    padding: 4rem;
  }

  .section-margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 576px) {
  .section-padding {
    padding: 4rem 2rem;
  }

  .section-margin {
    margin: 4rem 2rem;
  }  
}
.home-page-container{
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #FFF;
}

.company-logo img{
    width: 300px;
    height: auto;
    padding: 0.5rem 1rem;
}

.company-tagline h1{
    font-size:18px;
    color: #FF4700;
    font-weight: 200;
    letter-spacing: 2.5px;
    margin-bottom: 10rem;
}

.rebuilding-message{
    color: #32324D;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 200;
    padding: 0.5rem 0rem;;
}


.rebuilding-message span{
    color: #FF4700;
    font-weight: 300;
}

.bottom-container-old{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.footer-bottom-old{
    display: flex;
    justify-content: center;
    border-top: .5px outset #9F9F9F;
    border-bottom: .5px outset #9F9F9F;
    padding: 1rem 0rem; 
}

.email-container-old p{
    color: #32324D;
    font-family: var(--font-family);
    font-size: 14px;
    padding: 1rem 0rem;
    cursor: pointer;
}

.email-container-old a:hover{
    -webkit-text-decoration: underline solid #FF4700 1.5px;
            text-decoration: underline solid #FF4700 1.5px;
}

.footer-bottom-old p{
    color: #9F9F9F;
    font-family: var(--font-family);
    font-size: 14px;
}


/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 576px) {

    .footer-bottom-old{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


}
.teaser-container {
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f9f9f9;
  }

  /* .company-logo {
    margin-bottom: 3rem;
  } */
  .teaser-message-title {
    margin-bottom: 3rem;
  }

  .teaser-message-title h1{
    font-size: 18px;
    font-weight: 300;
    color: var(--color-lighttext);
  }
  
.company-logo img{
    width: 300px;
    height: auto;
    padding: 0.5rem 1rem;
}

.teaser-message h1{
    font-size:40px;
}
    
.bottom-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
}
  
.footer-bottom{
    display: flex;
    justify-content: center;
    border-top: .5px outset #9F9F9F;
    border-bottom: .5px outset #9F9F9F;
    padding: 2rem 0rem; 
}

.email-container p{
    color: #32324D;
    font-family: var(--font-family);
    font-size: 14px;
    padding: 1rem 0rem;
    cursor: pointer;
}

.email-container a:hover{
    -webkit-text-decoration: underline solid var(--color-secondary) 1.5px;
            text-decoration: underline solid var(--color-secondary) 1.5px;
}

.footer-bottom p{
    color: #9F9F9F;
    font-family: var(--font-family);
    font-size: 12px;
}

.notify-input-container{
    width: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;

}

.notify-input-container button{
    width: 30%;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0.25rem;
    color: #FFFFFF;
    background: var(--color-primary);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    outline: none;
    cursor: pointer;
    border: solid 1px;
    border-color: var(--color-primary);
    border-radius: 5px;

}

.notify-input-container button:hover{
    -webkit-filter: brightness(110%);
            filter: brightness(110%);
}

.notify-input-container button:focus{
    outline: none;
    border: 1px solid #4D4D4D; 
}

.notify-input-container input{
    width: 70%;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0rem;
    color: #32324D;
    font-family: var(--font-family);
    font-weight: 200;
    font-size: 14px;
    line-height: 25px;
    border: solid 1px;
    border-color: var(--color-primary);
    outline: none;
    border-radius: 5px;

}
    

.inp-error{
    font-family: var(--font-family); 
    font-size: 14px;
    font-weight: 400;
    color: var(--color-error);
    text-wrap:wrap;

}

.inp-success{
    font-family: var(--font-family); 
    font-size: 14px;
    font-weight: 400;
    color: var(--color-success);
    text-wrap:wrap;

}


/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 576px) {

    .footer-bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


}
.navbar {
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 4rem 1rem 4rem;
    background-color: #FFFFFF;
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    font-family: var(--font-family);
    color: var(--color-lighttext);
}

.navbar-links{
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-links-logo{
    margin-right: 2rem;
}

.navbar-links-logo img{
    -webkit-transform: scale(.90);
            transform: scale(.90);
}

.navbar-links-menu{
    display: flex;
    flex-direction: row;
}

.navbar-links-menu p,
.navbar-folded-menu-container-links p{
    /* color: var(--color-text); */
    /* font-family: var(--font-family);     */
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.navbar-cta {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
/* .navbar-cta p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    
} */



.navbar-cta button,
.navbar-folded-menu-container-cta button {
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    background: var(--color-primary);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}



.navbar-cta button:hover,
.navbar-folded-menu-container-cta button:hover {
    /* background-color: var(--color-button-hover); */
    -webkit-filter: brightness(110%);
            filter: brightness(110%);
}

.navbar-folded-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navbar-folded-menu svg {
    cursor: pointer;
}

.navbar-folded-menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background-color: #FFFFFF;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.navbar-folded-menu-container-links p {
    margin: 1rem 0;
}

.navbar-folded-menu-container-cta{
    display: none;
}



/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width:576px){
    .navbar{
        padding: 0.5re, 4rem 0.5rem 4rem;
    }
    .navbar-cta{
        display: none;
    }

    .navbar-folded-menu-container{
        top:20px;
    }

    .navbar-folded-menu-container-cta{
        display: block;
    }


}

@media screen and (max-width: 768px){
    .navbar{
        padding: 2rem, 4rem;
    }
}



@media screen and (max-width:992px){
    .navbar-links-menu{
        display: none;
    }

    .navbar-folded-menu{
        display: flex;
    }

}





.footer {
    display: flex;
    flex-direction: column;
    background: var(--color-bg);
    padding: 0rem 4rem 0rem 4rem;
    margin-top: 2rem;
    margin: 0rem 1rem;
}

.footer-top{
    display:flex;
    justify-content:left;
    padding: 2rem 0rem;
    border-top: .5px outset #9F9F9F
}

.footer-links,
.footer-notices{
    display: flex;
    flex-direction: column;
    align-items: left;
    
    
}

.footer-links-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

}

.footer-company{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    /* margin-right: 4rem;  */

}

/* .footer-company img{
    transform: scale(.90);
} */

.footer-links p,
.footer-notices p,
.footer-company p{
    font-family: var(--font-family);
    color: #4D4D4D;
    font-size: 14px;
    cursor: pointer;
    margin: 2px 0px;
}

.footer-company a{
    color: var(--color-primary);
}

.footer-links p:hover,
.footer-notices p:hover,
.footer-company p:hover{
    /* font-weight: 500; */
    /* text-decoration: underline; */
      -webkit-text-decoration: underline solid var(--color-secondary) 1.5px;
              text-decoration: underline solid var(--color-secondary) 1.5px;
}

.footer-bottom{
    display: flex;
    justify-content: center;
    border-top: .5px outset #9F9F9F;
    padding: 1rem 0rem; 
}

.footer-bottom p{
    color: #9F9F9F;
    font-family: var(--font-family);
    font-size: 12px;

}
/* .footer-bottom p{
    margin-right: 2px;
} */

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 768px){
    .footer {
        margin-top: 0rem;
    }
    
}

@media screen and (max-width: 576px){

    .footer-top{
        display:flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0rem;
        border-top: .5px outset #9F9F9F
    }

    .footer-bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: .5px outset #9F9F9F;
        padding: 1rem 0rem; 
    }

    .footer-links,
    .footer-notices{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    }

    .footer-links-container{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
    
    }

}



.hero {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem 4rem;
    min-height: 600px;
}

.hero-content {
    flex: 1 1;
    display: flex;
    align-items:flex-start;
    flex-direction: column;
    max-height: 400px;
    padding: 2rem 2rem 0rem 2rem;
}

.hero-content h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    text-align: left;
    
}

.hero-content p {
    font-family: var(--font-family);
    font-weight: 200;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.hero-content-action {
    display:flex;
    flex-direction: row;
    width: 100%;
    margin: 2rem 0 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



.main-action button {
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    background: #2375FF;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 1rem;
}

.main-action button:hover{
    /* background-color: var(--color-button-hover); */
    -webkit-filter: brightness(110%);
            filter: brightness(110%);
}




.sub-action button {
    padding: 0.5rem 1rem;
    color: #4D4D4D;
    background: #FFFFFF;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    border: 1px solid #4D4D4D;
    cursor: pointer;
    border-radius: 5px;
}


.hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image img {
    width: 600px;
    height: 400px;
}



/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 1200px){

    .hero-content h1 {
        font-weight: 500;
        font-size: 50px;
        line-height: 70px;
        letter-spacing: -0.04em;
        text-align: left;
        
    }

    .hero-content p {
        font-weight: 200;
        font-size: 16px;
        line-height: 24px;
    }



    .hero-image img {
        width: 500px;
        height: 320px;
    }
}

@media screen and (max-width: 992px){
    .hero-image{
        align-items: center;
    }

    .hero {
        flex-direction: column;
    }

    .hero-content {
        margin: 0 0 3rem;
    }

    .hero-content {
        padding: 2rem 0rem 0rem 0rem;
    }
    
}

@media screen and (max-width: 768px) {
    .hero-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .hero-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .hero-content-action button {
        font-size: 16px;
        line-height: 24px;
    }

    .hero-image img {
        width: 325px;
        height: 200px;
    }
}


@media screen and (max-width: 576px) {

    .hero-content{
        text-align: center;
        
    }
   .hero-content-action{
    justify-content: center;
   }

    .hero-content h1 {
        font-size: 30px;
        line-height: 42px;
        text-align: center;
    }

    .hero-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .hero-content-action button {
        font-size: 12px;
        line-height: 16px;
    }

    .hero-image img {
        width: 250px;
        height: 150px;
    }
}








.contact-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
    background-color: var(--color-bg);
}
.calendly-inline-widget{
    display: flex;
    width:100%;
    height: 700px;
}

.alternate-contact-info{
    font-family: var(--font-family);
    color: #4D4D4D;
    font-size: 16px;
    text-align: center;
    margin: 0rem 0.5rem 0rem 0.5rem;
}

.alternate-contact-info a{
    color: var(--color-primary);
}

.alternate-contact-info a:hover{
    -webkit-text-decoration: underline solid var(--color-secondary) 1.5px;
            text-decoration: underline solid var(--color-secondary) 1.5px;
}

.contact-title h1{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    border: 1px solid #ddd;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color:#F5F5F5;
}

.full-name,
.email,
.company,
.requirement{
    width: 100%;
    margin-bottom: 1rem;
}

.code-phone{
    margin-bottom: 1rem;
}

/* .company,
.requirement,
.solutions{
    margin-top:-1rem;
} */

.calendly-inline-widget{
    margin-top: 1rem;
}


form label,
form p {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    color: #2A2A2A;
}

form input {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    font-family: var(--font-family); 
    font-size: 12px;
    color: #4D4D4D;
}

form textarea {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    font-family: var(--font-family); 
    font-size: 12px;
    color: #4D4D4D;
}


.enabled-button {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    background: #2375FF;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    /* margin-top: 1rem; */
}

.enabled-button:hover{
    /* background-color: var(--color-button-hover); */
     /* background-color:#A259FF; */
     -webkit-filter: brightness(110%);
             filter: brightness(110%);
}

.enabled-button:focus{
    outline: none;
    border: 1px solid #4D4D4D; 
}

/* .disabled-button {
    width: 100%;
    padding: 0.5rem 1rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    border: none;
    outline: none;
    border-radius: 5px;
    background: #4D4D4D; 
    cursor:not-allowed;

  }
   */

label span{
    display: inline-flex;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 200;
    color: var(--color-error)

}

.solutions{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
}

.checkbox-container{
    display: flex;
    /* align-items: center; */
    padding-top: 5px;
}


.checkbox-container label{
    /* font-size: 12px;
    font-weight: 300;
    margin-left: 5px; */

    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    color: #2A2A2A;
    margin-left: 5px;

} 

/* input[type="checkbox"]:checked {
    background-color: #FFF;
} */

.checkbox-container-inp {
    display: flex;
}

.checkbox-container-lbl {
    font-weight: 300;
    margin-left: 5px;
}


.code-phone{
    display: flex;
    align-items: center;
    width: 100%;
}

.code {
    min-width: 130px;
    margin-right: 5px;;
    font-family: var(--font-family); 
    font-size: 12px;
    font-weight: 300;
    color: #4D4D4D;
}




.phone{
    width: 100%;
}

.inp-error{
    font-family: var(--font-family); 
    font-size: 12px;
    font-weight: 400;
    color: var(--color-error);
    text-wrap:wrap;

}

.inp-success{
    font-family: var(--font-family); 
    font-size: 12px;
    font-weight: 400;
    color: var(--color-success);
    text-wrap:wrap;

}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px; 
    height: 20px; 
    border-width: 5px;
    border: 0.25px solid #9F9F9F;
    color: #FFF;
    background-color: #FFF;
    border-radius: 3px;
    position: relative; /* Ensure proper positioning of the pseudo-element */
    /* outline: none; Ensure that focus styling is removed */
  }

input[type="checkbox"]:not(:checked) {
    background-color: #FFF;
}

input[type="checkbox"]:checked {
    background-color: var(--color-primary)
}

input[type="checkbox"]:checked::after {
    content: '\2713'; /* Unicode for check mark */
    color: #FFF; /* Color of the check mark */
    font-size: 16px; /* Adjust size as needed */
    position: absolute;
    top: 50%; /* Adjust vertical position */
    left: 50%; /* Adjust horizontal position */
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); /* Center the check mark */
}

.privacy-policy-acceptance{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 1rem;

}

.error-container{
    width: 60%;
    margin-top: -1.0rem;
    margin-bottom: 1rem;
    text-align: left;
}

/* input[type="text"],
input[type="email"],
input[type="phone"],
input[type="company"]
{
  height: 40px; 
  width: 100%; 
  padding: 8px; 
} */


/* textarea {
  height: 100px; 
  width: 100%; 
  padding: 8px; 
} */



/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 768px){

    .code-phone{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .code{
        width: 100%;
        margin-bottom: 5px;
    }
    
}
.cookie-banner a{
    font-weight: normal;
}

.cookie-banner a:hover{
    /* font-weight: bold; */
    text-decoration: underline; 
}

.cookie-banner p{
    color: #F5F5F5;
}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/
/* .content{
    margin: 1rem;
}    */

.feature-container {
    font-family: var(--font-family);
    color: var(--color-lighttext);
    max-width: 350px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 1px 1px 2px #9F9F9F;
    padding: 1.5rem;
    /* min-height: 300px; */
    min-height: 100%;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: #FFF;
}

.feature-container-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
}

.feature-container-title h1 {
    /* font-family: var(--font-family); */
    font-weight: 800;
    font-size: 18px;
    /* color: var(--color-text); */
    text-align: left;
}

.feature-container-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.feature-container-description {
    display: flex;
    width: 100%;
}

.feature-container-description p {
    /* font-family: var(--font-family); */
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    /* color: var(--color-text); */
}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 576px) {
    .feature-container-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .feature-container-description p {
        font-size: 12px;
        line-height: 20px;
    }

    .feature-container {
        margin: 1rem 0; 
    }
}
.cta-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem;
    margin-top: 2rem;
    margin-bottom: 1rem; 
}

.cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-radius: 1rem;
    background: var(--gradient-bar);
    padding: 2rem 4rem;
}

.cta-title {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.cta-title h2 { 
    font-family: var(--font-family);
    font-weight: 300;
}

.cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.cta-btn button {
    padding: 0.5rem 1rem;
    color: #ffffff;
    background: #170F49;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    border: 1px solid #170F49;;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.cta-btn button:hover {
    background-color: var(--color-button-hover2);
    /* color: #170F49; */
    /* filter: brightness(125%); */
}


/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 768px) {
    .cta {
        flex-direction: column;
    }

    .cta-btn {
        margin: 2rem 0 0;
    }

    .cta-title h2 {
       text-align: center;
    }

    .cta-container{
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
}

@media screen and (max-width: 576px) {
    .cta {
        flex-direction: column;
        margin: 4rem 2rem;
        
    }

    .cta-title h2 {
        font-size: 18px;
        line-height: 32px;
    }

    .cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}


.privacy-policy-container{
    margin: 6rem 20rem 2rem 20rem;
    font-family: var(--font-family);
}

h1{
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.privacy-policy-container h3{
    font-family: var(--font-family);
    color: #4D4D4D;
    font-weight: 500;
}

.privacy-policy-container p{
    font-family: var(--font-family);
    color: #4D4D4D;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
}


.privacy-policy-container a:hover{
      -webkit-text-decoration: underline solid var(--color-secondary) 1.5px;
              text-decoration: underline solid var(--color-secondary) 1.5px;
}





/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/


@media screen and (max-width: 1200px){
    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 50px;
  
    }

    .privacy-policy-container{
        margin: 6rem 12rem 2rem 12rem;
    }

}

@media screen and (max-width: 992px){
    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 50px;
        font-weight: 600;
  
    }

    .privacy-policy-container{
        margin: 6rem 10rem 2rem 10rem;
    }

}

@media screen and (max-width: 768px){
    
    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 40px;
        font-weight: 600;
  
    }

    .privacy-policy-container{
        margin: 6rem 5rem 2rem 5rem;

    }
    
    .privacy-policy-container h3{
        font-size: 18px;
        font-weight: 400;
    }
    
    .privacy-policy-container p{
        font-size: 16px;
        font-weight: 300;
    }
    .privacy-policy-container b{
        font-weight: 500;
    }

}


@media screen and (max-width: 576px) {

    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 30px;
        font-weight: 500;
    }


    .privacy-policy-container{
        margin: 6rem 3rem 2rem 3rem;
        text-align: center;
    }
    
    
    
    .privacy-policy-container h3{
        font-size: 16px;
        font-weight: 500;
    }
    
    .privacy-policy-container p{
        font-size: 14px;
        font-weight: 300;
    }
    .privacy-policy-container b{
        font-weight: 400;
    }

}


.cookie-policy-container{
    font-family: var(--font-family);
    margin: 6rem 20rem 2rem 20rem;
}

h1{
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-size: 50px;
}

.cookie-policy-container h3{
    font-family: var(--font-family);
    color: #4D4D4D;
    font-weight: 500;
}

.cookie-policy-container p{
    font-family: var(--font-family);
    color: #4D4D4D;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
}


.cookie-policy-container a:hover{
      -webkit-text-decoration: underline solid var(--color-secondary) 1.5px;
              text-decoration: underline solid var(--color-secondary) 1.5px;
}


/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 1200px){
    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 50px;
  
    }

    .cookie-policy-container{
        margin: 6rem 12rem 2rem 12rem;
    }

}

@media screen and (max-width: 992px){
    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 50px;
        font-weight: 600;
  
    }

    .cookie-policy-container{
        margin: 6rem 10rem 2rem 10rem;
    }

}

@media screen and (max-width: 768px){
    
    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 40px;
        font-weight: 600;
  
    }

    .cookie-policy-container{
        margin: 6rem 5rem 2rem 5rem;

    }
    
    .cookie-policy-container h3{
        font-size: 18px;
        font-weight: 400;
    }
    
    .cookie-policy-container p{
        font-size: 16px;
        font-weight: 300;
    }
    .cookie-policy-container b{
        font-weight: 500;
    }

}


@media screen and (max-width: 576px) {

    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 30px;
        font-weight: 500;
    }


    .cookie-policy-container{
        margin: 6rem 3rem 2rem 3rem;
        text-align: center;
    }
    
    
    
    .cookie-policy-container h3{
        font-size: 16px;
        font-weight: 500;
    }
    
    .cookie-policy-container p{
        font-size: 14px;
        font-weight: 300;
    }
    .cookie-policy-container b{
        font-weight: 400;
    }

}


.terms-of-service-container{
    margin: 6rem 20rem 2rem 20rem;
    font-family: var(--font-family);
}

h1{
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.terms-of-service-container h3{
    font-family: var(--font-family);
    color: #4D4D4D;
    font-weight: 500;
}

.terms-of-service-container p{
    font-family: var(--font-family);
    color: #4D4D4D;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
}


.terms-of-service-container a:hover{
      -webkit-text-decoration: underline solid var(--color-secondary) 1.5px;
              text-decoration: underline solid var(--color-secondary) 1.5px;
}


/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 1200px){
    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 50px;
  
    }

    .terms-of-service-container{
        margin: 6rem 12rem 2rem 12rem;
    }

}

@media screen and (max-width: 992px){
    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 50px;
        font-weight: 600;
  
    }

    .terms-of-service-container{
        margin: 6rem 10rem 2rem 10rem;
    }

}

@media screen and (max-width: 768px){
    
    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 40px;
        font-weight: 600;
  
    }

    .terms-of-service-container{
        margin: 6rem 5rem 2rem 5rem;

    }
    
    .terms-of-service-container h3{
        font-size: 18px;
        font-weight: 400;
    }
    
    .terms-of-service-container p{
        font-size: 16px;
        font-weight: 300;
    }

    .terms-of-service-container b{
        font-weight: 500;
    }

}


@media screen and (max-width: 576px) {

    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 30px;
        font-weight: 500;
    }


    .terms-of-service-container{
        margin: 6rem 3rem 2rem 3rem;
        text-align: center;
    }
    
    
    
    .terms-of-service-container h3{
        font-size: 16px;
        font-weight: 500;
    }
    
    .terms-of-service-container p{
        font-size: 14px;
        font-weight: 300;
    }
    .terms-of-service-container b{
        font-weight: 400;
    }

}
.blogcard-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  border-radius: 5px;
  /* padding: 10px; */
  width: 350px;
  min-width: 300px;
  height: 530px;
  /* box-shadow: 1px 1px 5px #9F9F9F; */

  background-color: #FFFFFF;
  font-family: var(--font-family);
  color: var(--color-lighttext);
}

.blogcard-container-minus-img{
  padding: 0px 10px; 
}

.blogcard-title {
  margin: 1rem 0rem; 
}

  .blogcard-title h1 {
    font-size: 18px; 
    font-weight: 500;
    text-align: left;
    margin-top: 5px;
    word-wrap: break-word; 
    word-break: break-all;
  }

  .blogcard-overview {
    margin: 1rem 0rem;
  }

  .blogcard-overview p {
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    /* color: hsl(0, 0%, 30%); */
    margin-top: 5px;
    line-height: 1.5;
  }

  .blogcard-date{
    margin: 3px 0px;

  }

  .blogcard-date p{
    font-size: 14px;
    font-weight: 300;
    /* color: #4D4D4D; */
  }

  .blogcard-date span{
    font-weight: 500;
    /* color: #4D4D4D; */
  }


  
  .blogcard-image img {
    height: 200px;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
  }
  

/* .blogcard-authors{
    display: flex;
    flex-direction: row;
    /* align-items: flex-start; 
  } */
    

  .blogcard-authors-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    /* padding: 3px 0px; */
    /* margin: 3px 0px;   */
  }

  .blogcard-authors-detail p{
    font-size: 14px;
    font-weight: 500;
    /* color: #4D4D4D; */

  }
  .blogcard-authors-detail p:hover{
    /* text-decoration: underline; */
    -webkit-text-decoration: underline solid var(--color-secondary) 1.5px;
            text-decoration: underline solid var(--color-secondary) 1.5px; 

  }


  /* .blogcard-authors-detail span {
    font-size: 14px; 
    font-weight: 500;
    color: #4D4D4D; 
  
  } */



.blogcard-authors-detail img{
    height: 40px;
    width: auto;
    margin-right:  5px;
    border-radius: 100%;
}

.blogcard-authors-detail img:hover{
  height: 40px;
  width: auto;
  margin-right:  5px;
  border-radius: 100%;
  border: 1.5px solid var(--color-secondary);
}



/* .blogcard-authors-detail p {
  font-size: 14px;
  font-weight: 500;
  color: #4D4D4D;
  align-self: center;

} */


  .blogcard-categories{
    /* font-family: var(--font-family); */
    display: flex;
    justify-content: center;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 500;
    align-items: center;

  }

   .blogcard-categories button{
        background-color: #FAC40C;
        color: #2A2A2A;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 3px;
        padding: 6px 8px;
        margin: 4px 3px 3px 0px ;
        
   }

   .blogcard-categories button:hover{
      -webkit-filter: brightness(110%);
              filter: brightness(110%);
  }
  

   .blogcard-readarticle{
    display: flex;
    justify-content: right;
    margin-top: 5px;
   }

   .blogcard-readarticle button{
    background-color:transparent;
    border: none;
    outline: none;
    /* outline: 1px; */
    /* border: 1px solid var(--color-primary);  */
    /* border-color: var(--color-primary); */
    cursor: pointer;
    padding: 6px 8px;
    color: var(--color-secondary);
    /* color: var(--color-lighttext); */
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    /* border-radius: 5px; */
    font-weight: 500;
    font-size: 14px;
    align-items: center;
   }

   /* .blogcard-readarticle button:hover{
    text-decoration: underline solid var(--color-secondary) 1.5px; 
  } */



.blogcard-readarticle button:hover{
  /* background-color: var(--color-primary);  */
  background-color: var(--color-secondary); 
  /* background-color: #9F9F9F; */
  /* background-color: #FAC40C; */
    color: #FFFFFF;
  /* color: #2A2A2A; */
  /* outline: 1px; */
  /* border: 1px solid var(--color-primary);  */
  /* border-color: var(--color-primary); */
  cursor: pointer;
  padding: 6px 8px;

  /* margin: 3px; */
  /* width: 150px;
  height: 50px; */
  border-radius: 5px;
  /* font-weight: 400; */
  /* font-size: 14px; */
  /* align-items: center; */
 }



  
   /* Breakpoints Points
   XX small devices (portrait phones) upto
   @media screen and (max-width: 414px) {}

   X small devices (portrait phones) upto
   @media screen and (max-width: 576px) {}

   Small devices (landscape phones) upto
   @media screen and (max-width: 768px){}

   Medium devices (tablets) upto
   @media screen and (max-width: 992px){}

   Large devices (desktops) upto
   @media screen and (max-width: 1200px){} */

   @media screen and (max-width: 576px) {

    /* .blogcard-title h1 {
      font-size: 14px; 
      text-wrap:wrap;
    }
  
    /* .blogcard-overview p {
      font-size: 12px;
      font-weight: 300;
      text-align: left;
      text-wrap:wrap;
      color: #4D4D4D;
      margin-top: 5px;
    } */

    /*.blogcard-overview p{
      display: none;
    } */


   }
.related-articles-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem;
}

.related-articles-top-panel{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0rem 4rem 0rem 6rem;
    margin-bottom: 2rem;
}

.related-articles-title h1{
    font-family: var(--font-family);
    font-size: 30px;
    font-weight: 500;
    color: #2A2A2A;

}

.ralated-articles-browseall button{
    background-color: #FFFFFF;
    /* outline: 1px; */
    border: 1px solid var(--color-primary); 
    border-color: var(--color-primary);
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: var(--color-primary);
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content:last baseline;
    margin-right: 50px;
}

.ralated-articles-browseall button:hover{
    background-color: var(--color-primary); 
    /* outline: 1px; */
    /* border: 1px solid var(--color-primary);  */
    /* border-color: var(--color-primary); */
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content:last baseline;
    margin-right: 50px;
}



.related-blogcards{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .related-blogcards div{
    margin: 0px 5px;
} */

.related-blogcards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    gap: 20px;
    
}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {} 

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/


@media screen and (max-width: 992px){
    .related-blogcards {
        grid-template-columns: repeat(2, 1fr);
      }

}

@media screen and (max-width: 576px) {
    .related-blogcards {
        grid-template-columns: repeat(1, 1fr);
      }

}

.blog-hero-container{
    /* margin-top: 6rem; */
    font-family: var(--font-family);
    /* color: #FFF; */
    /* color: #F5F5F5; */
    color:#9F9F9F;
    padding: 2rem 10rem; 
    /* width: 600px; */
    /* height: 200px; */
}

.blog-hero-image{
    width: 100%;
    height: 500px;
    background-size: cover; 
    /* background-repeat: no-repeat;  */
    background-position: center; 
    overflow: hidden;
   

}


.blog-hero-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 0rem 5rem;
    height: 100%;
}


.blog-hero-title{
    margin: 2rem 0rem 1rem 0rem;
}

.blog-hero-title h3{
    color: #FAC40C;
    font-weight: 300;
}

.blog-hero-title h1{
    text-align: left;
    font-size: 30px;
    font-weight: 500;
    color: #F5F5F5;
    
}


.blog-hero-overview{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
    margin-bottom: 1rem;
}



.blog-hero-authors-detail{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 30px;
}

.blog-hero-authors{
    display: flex;
    justify-content: left;
    align-items: center;
    
}


.blog-hero-author-image img{
    width: 75px;
    height: 75px;
    border-radius: 100%;
}


.blog-author-hero-name{
    font-size: 18px;
    font-weight: 400;
    margin-left: 5px;
}

.blog-hero-date{
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 2rem;
}

/* .blog-article-categories{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;

} */



/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 576px) {

    .blog-hero-container{
        padding: 2rem 4rem;
    }

    .blog-hero-title h3{
        color: #FAC40C;
        font-weight: 200;
    }
    
    .blog-hero-title h1{
        text-align: left;
        font-size: 24px;
        font-weight: 400;
        color: #F5F5F5;
        
    }

    .blog-hero-overview{
        display: none;
    }

    .blog-hero-author-image img{
        width: 50px;
        height: 50px;
        border-radius: 100%;
    }
    
    
    .blog-author-hero-name{
        font-size: 14px;
        font-weight: 300;
        margin-left: 5px;
    }

    .blog-hero-date{
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 2rem;
    }

   
}

@media screen and (max-width: 768px){

    .blog-hero-title h3{
        color: #FAC40C;
        font-weight: 200;
    }

    .blog-hero-title h1{
        text-align: left;
        font-size: 26px;
        font-weight: 400;
        color: #F5F5F5;
        
    }

    .blog-hero-overview{
        display: none;
    }

    .blog-hero-author-image img{
        width: 50px;
        height: 50px;
        border-radius: 100%;
    }
    
    
    .blog-author-hero-name{
        font-size: 14px;
        font-weight: 300;
        margin-left: 5px;
    }


}

/* @media screen and (max-width: 992px)  */
@media screen and (min-width: 769px) and (max-width: 992px) {

    .blog-hero-title h3{
        color: #FAC40C;
        font-weight: 300;
    }
    
    .blog-hero-title h1{
        text-align: left;
        font-size: 26px;
        font-weight: 400;
        color: #F5F5F5;
        
    }
    
    
    .blog-hero-overview{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 200;
        text-align: left;
        margin-bottom: 1rem;
    }
    
    .blog-hero-author-image img{
        width: 60px;
        height: 60px;
        border-radius: 100%;
    }
    
    
    .blog-author-hero-name{
        font-size: 16px;
        font-weight: 200;
        margin-left: 5px;
    }

    .blog-hero-date{
        font-size: 16px;
        font-weight: 200;
        margin-bottom: 2rem;
    }


}

.pagenotfound-container {
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f9f9f9;
}

.notound-message{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.notound-message h1{
    font-size: 50px;
}

.notound-message p{
    font-size: 18px;
}

.back-home-link{
    margin: 2rem 0rem;
    font-size: 20px;

}

.back-home-link button{
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    background: var(--color-primary);
    font-family: var(--font-family);
    font-weight: 400;   
    font-size: 16px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.back-home-link button{
    -webkit-filter: brightness(110%);
            filter: brightness(110%);
}

.home{
    margin-top: 5rem;
    margin-bottom: 5rem;
}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/
.convai {
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem;
    align-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem; 
}


.convai-title {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    width: 100%;
}
.convai-title h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
}

.convai-features{
    display: flex;
    justify-content: center;

}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 992px){
    .convai-features {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    .convai {
        margin-top: 0rem;
        margin-bottom: 0rem;
   
}
}

@media screen and (max-width: 576px) {
    .convai-features {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .convai-title h1 {
        font-weight: 500;
        font-size: 30px;
        text-align: center;

    }
 
}
.technologies{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem;
    margin-top: 2rem;
    margin-bottom: 1rem; 

}

.technologies h1{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 2rem;

}

.technologies-container{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}


.huggingface-container,
.openai-container,
.aws-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 80px;
    padding: .5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    box-shadow: 1px 1px 3px #888888;
    

}

.huggingface-container img,
.openai-container img,
.aws-container img{
    max-width: 100%;

}

/* .huggingface-container img{
    transform: scale(1.2);
}*/

.aws-container img{
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
} 




/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/


@media screen and (max-width: 768px){

    .technology-header{
       text-align: center;
    }

    .technologies{
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
}


@media screen and (max-width: 576px){

    .technologies-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    .technology-header h1 {
        font-weight: 500;
        font-size: 30px;
        text-align: center;
    }
}


.offered-solutions-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 4rem;
    margin-top: 2rem;
    margin-bottom: 1rem; 
    
}

.offered-solutions-title {
    flex: 1 1;
    display: flex;
    align-content: center;
    justify-content: center;    
    margin-bottom: 3rem;
    width: 100%;
}

.offered-solutions-title  h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
}

.offered-solutions-cards {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 992px){
    .offered-solutions-cards {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    .offered-solutions-container {
        margin-top: 0rem;
        margin-bottom: 0rem;     
    }
   
}

@media screen and (max-width: 576px) {
    .offered-solutions-cards  {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .offered-solutions-title h1 {
        font-weight: 500;
        font-size: 30px;
        text-align: center;

    }


 
}

.company-container{
    margin-top: 6rem;
    font-family: var(--font-family);
}

h1{
    text-align: center;
}



/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/
.solutions-page-container{
    margin-top: 6rem;
    font-family: var(--font-family);
}

h1{
    text-align: center;
}






/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/
.blog-section-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 2rem 4rem;
    margin-top: 2rem;
    background-color: #F5F5F5;
}


.blog-next-prev-buttons{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.blog-navigate-all{
    display: flex;
    justify-content: left;
    margin-bottom: 10px;
}

/* .blog-navigation-buttons {
    position: relative;
    display: flex;
    justify-content:left
  } */

.blog-navigate-next,
.blog-navigate-previous {
    display: flex;
    align-items: center;  
    justify-content: center;
    background-color: var(--color-primary);
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    margin: 3px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
  
}



.blog-navigate-next:hover,
.blog-navigate-previous:hover {
  background-color: var(--color-button-hover);
}

.blog-navigate-all-button {
    background-color: #FFFFFF;
    /* outline: 1px; */
    border: 1px solid var(--color-primary); 
    border-color: var(--color-primary);
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: var(--color-primary);
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content:last baseline;
    margin-right: 50px;
}

.blog-navigate-all-button:hover {
    background-color: var(--color-primary); 
    /* outline: 1px; */
    /* border: 1px solid var(--color-primary);  */
    /* border-color: var(--color-primary); */
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content:last baseline;
    margin-right: 50px;

}




.blog-section-title {
    flex: 1 1;
    display: flex;
    align-content: center;
    justify-content: center;    
    margin-bottom: 3rem;
    width: 100%;
}

.blog-section-title   h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
}


.blog-section-cards{
    display: flex;
    flex-direction: row;
    /* justify-content: left;
    align-items: center;
    margin-bottom: 10px; */
    
}



/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {} 

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 992px){
  
}


@media screen and (max-width: 768px) {

  
} 



@media screen and (max-width: 576px) {
    .blog-section-container{
        padding: 2rem 4rem;
        width: 100%;
        justify-content: center;
    }

    .blog-section-title h1 {
        font-weight: 500;
        font-size: 30px;
        text-align: center;
    }

}

.blog-section-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 2rem 4rem;
    margin-top: 2rem;
    background-color: #F5F5F5;
}

.blog-navigate-all{
    display: flex;
    width: 100%;
    justify-content: center;
}

.blog-section-pagitation-prev button,
.blog-section-pagitation-next button {
    display: flex;
    align-items: center;  
    justify-content: center;
    background-color: var(--color-primary);
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    margin: 3px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
  
}

.blog-section-pagitation-prev button:hover,
.blog-section-pagitation-next button:hover {
  /* background-color: var(--color-button-hover); */
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.blog-navigate-all-button {
    background-color: #FFFFFF;
    /* outline: 1px; */
    border: 1px solid var(--color-primary); 
    border-color: var(--color-primary);
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: var(--color-primary);
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content:last baseline;
}

.blog-section-pagitation-prev button[disabled],
.blog-section-pagitation-next button[disabled] {
    /* background-color: #4D4D4D; */
    background-color: #9F9F9F;
    cursor: not-allowed;  
    -webkit-filter: brightness(100%);  
            filter: brightness(100%);
}

.blog-navigate-all-button:hover {
    background-color: var(--color-primary); 
    /* outline: 1px; */
    /* border: 1px solid var(--color-primary);  */
    /* border-color: var(--color-primary); */
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content:last baseline;

}

.blog-section-title {
    flex: 1 1;
    display: flex;
    align-content: center;
    justify-content: center;    
    margin-bottom: 3rem;
    width: 100%;
}

.blog-section-title   h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
}


.blog-section-cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    gap: 20px;
    
}

.blog-section-pagitation-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;

}




/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {} 

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 576px) {
    .blog-section-cards {
        grid-template-columns: repeat(1, 1fr) !important;
      }

      .blog-section-title h1 {
        font-size: 30px;
        line-height: 42px;
        text-align: center;
        font-weight: 500;
    }

}


@media screen and (max-width: 992px){
    .blog-section-cards {
        grid-template-columns: repeat(2, 1fr);
      }

}
.blog-article-container{
    margin-top: 6rem;
    font-family: var(--font-family);
    color: var(--color-lighttext);
    padding: 2rem 15rem 2rem 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-article-title{
    margin: 2rem 0rem 0.5rem 0rem;
}

.blog-article-title h1{
    /* font-family: var(--font-family); */
    font-size: 30px;
    /* color: #2A2A2A; */
    font-weight: 500;
}


.blog-article-image{
    display: flex;
    justify-content: center;
}

.blog-article-image img{
    width: 800px;
    height: 400px;
    margin-bottom: 2rem;

}

.blog-article-overview{
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-family: var(--font-family); */
    /* color: #4D4D4D; */
    margin-bottom: 2rem;
}

.blog-article-overview{
    font-size: 20px;
    text-align: left;
}

.blog-authors-detail{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    margin-bottom: 2rem;
    
}

.blog-article-authors{
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-author-image img{
    width: 75px;
    height: 75px;
    border-radius: 100%;
}
.blog-author-image img:hover{
    width: 75px;
    height: 75px;
    border-radius: 100%;
    border: 1.5px solid var(--color-secondary);

}

.blog-author-name{
    /* font-family: var(--font-family); */
    font-size: 18px;
    /* color: #4D4D4D; */
    margin-left: 15px;
}

.blog-author-name p:hover {
    -webkit-text-decoration: underline solid var(--color-secondary) 1.5px;
            text-decoration: underline solid var(--color-secondary) 1.5px; 
}

.blog-article-date{
    /* font-family: var(--font-family); */
    font-size: 18px;
    /* color: #4D4D4D; */
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.blog-article-categories{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;

}

.blog-article-categories button {
    background-color: #FAC40C;
    /* font-family: var(--font-family); */
    font-size: 18px;
    /* color: #4D4D4D; */
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
    padding: .5rem 1rem;
    margin: 4px 4px 4px 4px;
    text-wrap:nowrap
}

.blog-article-categories button:hover {
    -webkit-filter: brightness(110%);
            filter: brightness(110%);
}

.blog-article-head-section{
 
    width: 100%;
    min-width: 350px;
    margin-bottom: 3rem;
}


.blog-article-section-header{
    font-size: 18px;
    text-align: center;
    /* color: var(--color-primary); */
}

.blog-article-section-header h2{
    font-family: var(--font-family);
    color: var(--color-lighttext);
    font-size: 22px;
    text-align: center;
    font-weight: 600;
} 

.blog-article-sections-button h2{
    font-family: var(--font-family);
    /* color: var(--color-lighttext); */
    /* color: var(--color-primary); */
    font-size: 20px;
    text-align: left;
    font-weight: 300;
}



.blog-article-sections button{
    margin: 5px 0px;
    /* background: var(--color-secondary); */
    background: #FFF;
    /* color: #FFF; */
    color: var(--color-lighttext);
    width: 100%;
    padding: 5px 5px 5px 24px;
    /* background: none; */
    outline: none;
    border: none;
    /* cursor: pointer; */
    /* border-radius: 5px; */
    /* border: 1px solid var(--color-secondary);  */
    border: 1px solid var(--color-lighttext);
}


.blog-article-sections button:hover{
    /* filter: brightness(110%); */
    /* filter: opacity(0.75); */
    /* background: var(--color-secondary); */
    /* color: #FFF; */
    padding: 5px 5px 5px 17px;
    color: var(--color-lighttext);
    outline: none;
    cursor: pointer;
    /* border-radius: 5px; */
    /* border: 1px solid var(--color-secondary);  */
    border-left: 8px solid var(--color-secondary); /* Increase left border thickness */
    border-top: 1px solid var(--color-secondary);
    border-right: 1px solid var(--color-secondary);
    border-bottom: 1px solid var(--color-secondary);
}

.blog-article-body-section{
    text-align: justify;
    margin-bottom: 2rem;
    line-height: 1.8;
    min-width: 350px;

}

.blog-article-body-section h2{
    font-family: var(--font-family);
    color: var(--color-lighttext);
    font-size: 22px;
    text-align: left;
    font-weight: 600;
    
}

.blog-article-body-section p{
    font-size: 18px;
    text-align: left;
}
/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/
.blog-landing-page-container{
    margin-top: 6rem;
    font-family: var(--font-family);
}

h1{
    text-align: center;
}

.blog-article-browse-section{
    margin-top: -6rem;
}

.blog-category-container{
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem;
    
}

.blog-category-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
}

.blog-category-buttons button{
    background-color: #FAC40C;
    font-family: var(--font-family);
    font-size: 18px;
    color: #4D4D4D;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
    padding: .5rem 1rem;
    margin: 4px 4px 4px 4px;

}

.blog-category-buttons button:hover{
    -webkit-filter: brightness(110%);
            filter: brightness(110%);
}


.category-blogcards{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    gap: 20px;
}

.category-blogcards-pagination-buttons  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;

}

.category-blogcards-pagination button{
    display: flex;
    align-items: center;  
    justify-content: center;
    background-color: var(--color-primary);
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    margin: 3px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
}

.category-blogcards-pagination button:hover {
    /* background-color: var(--color-button-hover); */
    -webkit-filter: brightness(110%);
            filter: brightness(110%);
  }

.category-blogcards-pagination-prev button[disabled],
.category-blogcards-pagination-next button[disabled] {
  /* background-color: #4D4D4D; */
  background-color: #9F9F9F;
  cursor: not-allowed;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

  /* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 576px) {
    .category-blogcards {
        grid-template-columns: repeat(1, 1fr) !important;
      }

}


@media screen and (max-width: 992px){
    .category-blogcards {
        grid-template-columns: repeat(2, 1fr);
      }

}
