.teaser-container {
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f9f9f9;
  }

  /* .company-logo {
    margin-bottom: 3rem;
  } */
  .teaser-message-title {
    margin-bottom: 3rem;
  }

  .teaser-message-title h1{
    font-size: 18px;
    font-weight: 300;
    color: var(--color-lighttext);
  }
  
.company-logo img{
    width: 300px;
    height: auto;
    padding: 0.5rem 1rem;
}

.teaser-message h1{
    font-size:40px;
}
    
.bottom-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
}
  
.footer-bottom{
    display: flex;
    justify-content: center;
    border-top: .5px outset #9F9F9F;
    border-bottom: .5px outset #9F9F9F;
    padding: 2rem 0rem; 
}

.email-container p{
    color: #32324D;
    font-family: var(--font-family);
    font-size: 14px;
    padding: 1rem 0rem;
    cursor: pointer;
}

.email-container a:hover{
    text-decoration: underline solid var(--color-secondary) 1.5px;
}

.footer-bottom p{
    color: #9F9F9F;
    font-family: var(--font-family);
    font-size: 12px;
}

.notify-input-container{
    width: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;

}

.notify-input-container button{
    width: 30%;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0.25rem;
    color: #FFFFFF;
    background: var(--color-primary);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    outline: none;
    cursor: pointer;
    border: solid 1px;
    border-color: var(--color-primary);
    border-radius: 5px;

}

.notify-input-container button:hover{
    filter: brightness(110%);
}

.notify-input-container button:focus{
    outline: none;
    border: 1px solid #4D4D4D; 
}

.notify-input-container input{
    width: 70%;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0rem;
    color: #32324D;
    font-family: var(--font-family);
    font-weight: 200;
    font-size: 14px;
    line-height: 25px;
    border: solid 1px;
    border-color: var(--color-primary);
    outline: none;
    border-radius: 5px;

}
    

.inp-error{
    font-family: var(--font-family); 
    font-size: 14px;
    font-weight: 400;
    color: var(--color-error);
    text-wrap:wrap;

}

.inp-success{
    font-family: var(--font-family); 
    font-size: 14px;
    font-weight: 400;
    color: var(--color-success);
    text-wrap:wrap;

}


/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 576px) {

    .footer-bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


}