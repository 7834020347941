.navbar {
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 4rem 1rem 4rem;
    background-color: #FFFFFF;
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    font-family: var(--font-family);
    color: var(--color-lighttext);
}

.navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-links-logo{
    margin-right: 2rem;
}

.navbar-links-logo img{
    transform: scale(.90);
}

.navbar-links-menu{
    display: flex;
    flex-direction: row;
}

.navbar-links-menu p,
.navbar-folded-menu-container-links p{
    /* color: var(--color-text); */
    /* font-family: var(--font-family);     */
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.navbar-cta {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
/* .navbar-cta p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    
} */



.navbar-cta button,
.navbar-folded-menu-container-cta button {
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    background: var(--color-primary);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}



.navbar-cta button:hover,
.navbar-folded-menu-container-cta button:hover {
    /* background-color: var(--color-button-hover); */
    filter: brightness(110%);
}

.navbar-folded-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navbar-folded-menu svg {
    cursor: pointer;
}

.navbar-folded-menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background-color: #FFFFFF;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.navbar-folded-menu-container-links p {
    margin: 1rem 0;
}

.navbar-folded-menu-container-cta{
    display: none;
}



/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width:576px){
    .navbar{
        padding: 0.5re, 4rem 0.5rem 4rem;
    }
    .navbar-cta{
        display: none;
    }

    .navbar-folded-menu-container{
        top:20px;
    }

    .navbar-folded-menu-container-cta{
        display: block;
    }


}

@media screen and (max-width: 768px){
    .navbar{
        padding: 2rem, 4rem;
    }
}



@media screen and (max-width:992px){
    .navbar-links-menu{
        display: none;
    }

    .navbar-folded-menu{
        display: flex;
    }

}




