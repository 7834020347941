.solutions-page-container{
    margin-top: 6rem;
    font-family: var(--font-family);
}

h1{
    text-align: center;
}






/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/