.footer {
    display: flex;
    flex-direction: column;
    background: var(--color-bg);
    padding: 0rem 4rem 0rem 4rem;
    margin-top: 2rem;
    margin: 0rem 1rem;
}

.footer-top{
    display:flex;
    justify-content:left;
    padding: 2rem 0rem;
    border-top: .5px outset #9F9F9F
}

.footer-links,
.footer-notices{
    display: flex;
    flex-direction: column;
    align-items: left;
    
    
}

.footer-links-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

}

.footer-company{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    /* margin-right: 4rem;  */

}

/* .footer-company img{
    transform: scale(.90);
} */

.footer-links p,
.footer-notices p,
.footer-company p{
    font-family: var(--font-family);
    color: #4D4D4D;
    font-size: 14px;
    cursor: pointer;
    margin: 2px 0px;
}

.footer-company a{
    color: var(--color-primary);
}

.footer-links p:hover,
.footer-notices p:hover,
.footer-company p:hover{
    /* font-weight: 500; */
    /* text-decoration: underline; */
      text-decoration: underline solid var(--color-secondary) 1.5px;
}

.footer-bottom{
    display: flex;
    justify-content: center;
    border-top: .5px outset #9F9F9F;
    padding: 1rem 0rem; 
}

.footer-bottom p{
    color: #9F9F9F;
    font-family: var(--font-family);
    font-size: 12px;

}
/* .footer-bottom p{
    margin-right: 2px;
} */

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 768px){
    .footer {
        margin-top: 0rem;
    }
    
}

@media screen and (max-width: 576px){

    .footer-top{
        display:flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0rem;
        border-top: .5px outset #9F9F9F
    }

    .footer-bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: .5px outset #9F9F9F;
        padding: 1rem 0rem; 
    }

    .footer-links,
    .footer-notices{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    }

    .footer-links-container{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
    
    }

}


