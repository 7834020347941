.blogcard-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  border-radius: 5px;
  /* padding: 10px; */
  width: 350px;
  min-width: 300px;
  height: 530px;
  /* box-shadow: 1px 1px 5px #9F9F9F; */

  background-color: #FFFFFF;
  font-family: var(--font-family);
  color: var(--color-lighttext);
}

.blogcard-container-minus-img{
  padding: 0px 10px; 
}

.blogcard-title {
  margin: 1rem 0rem; 
}

  .blogcard-title h1 {
    font-size: 18px; 
    font-weight: 500;
    text-align: left;
    margin-top: 5px;
    word-wrap: break-word; 
    word-break: break-all;
  }

  .blogcard-overview {
    margin: 1rem 0rem;
  }

  .blogcard-overview p {
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    /* color: hsl(0, 0%, 30%); */
    margin-top: 5px;
    line-height: 1.5;
  }

  .blogcard-date{
    margin: 3px 0px;

  }

  .blogcard-date p{
    font-size: 14px;
    font-weight: 300;
    /* color: #4D4D4D; */
  }

  .blogcard-date span{
    font-weight: 500;
    /* color: #4D4D4D; */
  }


  
  .blogcard-image img {
    height: 200px;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
  }
  

/* .blogcard-authors{
    display: flex;
    flex-direction: row;
    /* align-items: flex-start; 
  } */
    

  .blogcard-authors-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    /* padding: 3px 0px; */
    /* margin: 3px 0px;   */
  }

  .blogcard-authors-detail p{
    font-size: 14px;
    font-weight: 500;
    /* color: #4D4D4D; */

  }
  .blogcard-authors-detail p:hover{
    /* text-decoration: underline; */
    text-decoration: underline solid var(--color-secondary) 1.5px; 

  }


  /* .blogcard-authors-detail span {
    font-size: 14px; 
    font-weight: 500;
    color: #4D4D4D; 
  
  } */



.blogcard-authors-detail img{
    height: 40px;
    width: auto;
    margin-right:  5px;
    border-radius: 100%;
}

.blogcard-authors-detail img:hover{
  height: 40px;
  width: auto;
  margin-right:  5px;
  border-radius: 100%;
  border: 1.5px solid var(--color-secondary);
}



/* .blogcard-authors-detail p {
  font-size: 14px;
  font-weight: 500;
  color: #4D4D4D;
  align-self: center;

} */


  .blogcard-categories{
    /* font-family: var(--font-family); */
    display: flex;
    justify-content: center;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 500;
    align-items: center;

  }

   .blogcard-categories button{
        background-color: #FAC40C;
        color: #2A2A2A;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 3px;
        padding: 6px 8px;
        margin: 4px 3px 3px 0px ;
        
   }

   .blogcard-categories button:hover{
      filter: brightness(110%);
  }
  

   .blogcard-readarticle{
    display: flex;
    justify-content: right;
    margin-top: 5px;
   }

   .blogcard-readarticle button{
    background-color:transparent;
    border: none;
    outline: none;
    /* outline: 1px; */
    /* border: 1px solid var(--color-primary);  */
    /* border-color: var(--color-primary); */
    cursor: pointer;
    padding: 6px 8px;
    color: var(--color-secondary);
    /* color: var(--color-lighttext); */
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    /* border-radius: 5px; */
    font-weight: 500;
    font-size: 14px;
    align-items: center;
   }

   /* .blogcard-readarticle button:hover{
    text-decoration: underline solid var(--color-secondary) 1.5px; 
  } */



.blogcard-readarticle button:hover{
  /* background-color: var(--color-primary);  */
  background-color: var(--color-secondary); 
  /* background-color: #9F9F9F; */
  /* background-color: #FAC40C; */
    color: #FFFFFF;
  /* color: #2A2A2A; */
  /* outline: 1px; */
  /* border: 1px solid var(--color-primary);  */
  /* border-color: var(--color-primary); */
  cursor: pointer;
  padding: 6px 8px;

  /* margin: 3px; */
  /* width: 150px;
  height: 50px; */
  border-radius: 5px;
  /* font-weight: 400; */
  /* font-size: 14px; */
  /* align-items: center; */
 }



  
   /* Breakpoints Points
   XX small devices (portrait phones) upto
   @media screen and (max-width: 414px) {}

   X small devices (portrait phones) upto
   @media screen and (max-width: 576px) {}

   Small devices (landscape phones) upto
   @media screen and (max-width: 768px){}

   Medium devices (tablets) upto
   @media screen and (max-width: 992px){}

   Large devices (desktops) upto
   @media screen and (max-width: 1200px){} */

   @media screen and (max-width: 576px) {

    /* .blogcard-title h1 {
      font-size: 14px; 
      text-wrap:wrap;
    }
  
    /* .blogcard-overview p {
      font-size: 12px;
      font-weight: 300;
      text-align: left;
      text-wrap:wrap;
      color: #4D4D4D;
      margin-top: 5px;
    } */

    /*.blogcard-overview p{
      display: none;
    } */


   }