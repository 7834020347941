.convai {
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem;
    align-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem; 
}


.convai-title {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    width: 100%;
}
.convai-title h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
}

.convai-features{
    display: flex;
    justify-content: center;

}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 992px){
    .convai-features {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    .convai {
        margin-top: 0rem;
        margin-bottom: 0rem;
   
}
}

@media screen and (max-width: 576px) {
    .convai-features {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .convai-title h1 {
        font-weight: 500;
        font-size: 30px;
        text-align: center;

    }
 
}