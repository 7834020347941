.related-articles-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem;
}

.related-articles-top-panel{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0rem 4rem 0rem 6rem;
    margin-bottom: 2rem;
}

.related-articles-title h1{
    font-family: var(--font-family);
    font-size: 30px;
    font-weight: 500;
    color: #2A2A2A;

}

.ralated-articles-browseall button{
    background-color: #FFFFFF;
    /* outline: 1px; */
    border: 1px solid var(--color-primary); 
    border-color: var(--color-primary);
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: var(--color-primary);
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content:last baseline;
    margin-right: 50px;
}

.ralated-articles-browseall button:hover{
    background-color: var(--color-primary); 
    /* outline: 1px; */
    /* border: 1px solid var(--color-primary);  */
    /* border-color: var(--color-primary); */
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content:last baseline;
    margin-right: 50px;
}



.related-blogcards{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .related-blogcards div{
    margin: 0px 5px;
} */

.related-blogcards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    
}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {} 

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/


@media screen and (max-width: 992px){
    .related-blogcards {
        grid-template-columns: repeat(2, 1fr);
      }

}

@media screen and (max-width: 576px) {
    .related-blogcards {
        grid-template-columns: repeat(1, 1fr);
      }

}
