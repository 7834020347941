

.cookie-policy-container{
    font-family: var(--font-family);
    margin: 6rem 20rem 2rem 20rem;
}

h1{
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-size: 50px;
}

.cookie-policy-container h3{
    font-family: var(--font-family);
    color: #4D4D4D;
    font-weight: 500;
}

.cookie-policy-container p{
    font-family: var(--font-family);
    color: #4D4D4D;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
}


.cookie-policy-container a:hover{
      text-decoration: underline solid var(--color-secondary) 1.5px;
}


/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 1200px){
    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 50px;
  
    }

    .cookie-policy-container{
        margin: 6rem 12rem 2rem 12rem;
    }

}

@media screen and (max-width: 992px){
    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 50px;
        font-weight: 600;
  
    }

    .cookie-policy-container{
        margin: 6rem 10rem 2rem 10rem;
    }

}

@media screen and (max-width: 768px){
    
    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 40px;
        font-weight: 600;
  
    }

    .cookie-policy-container{
        margin: 6rem 5rem 2rem 5rem;

    }
    
    .cookie-policy-container h3{
        font-size: 18px;
        font-weight: 400;
    }
    
    .cookie-policy-container p{
        font-size: 16px;
        font-weight: 300;
    }
    .cookie-policy-container b{
        font-weight: 500;
    }

}


@media screen and (max-width: 576px) {

    h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 30px;
        font-weight: 500;
    }


    .cookie-policy-container{
        margin: 6rem 3rem 2rem 3rem;
        text-align: center;
    }
    
    
    
    .cookie-policy-container h3{
        font-size: 16px;
        font-weight: 500;
    }
    
    .cookie-policy-container p{
        font-size: 14px;
        font-weight: 300;
    }
    .cookie-policy-container b{
        font-weight: 400;
    }

}