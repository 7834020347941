.pagenotfound-container {
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f9f9f9;
}

.notound-message{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.notound-message h1{
    font-size: 50px;
}

.notound-message p{
    font-size: 18px;
}

.back-home-link{
    margin: 2rem 0rem;
    font-size: 20px;

}

.back-home-link button{
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    background: var(--color-primary);
    font-family: var(--font-family);
    font-weight: 400;   
    font-size: 16px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.back-home-link button{
    filter: brightness(110%);
}