.blog-section-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 2rem 4rem;
    margin-top: 2rem;
    background-color: #F5F5F5;
}

.blog-navigate-all{
    display: flex;
    width: 100%;
    justify-content: center;
}

.blog-section-pagitation-prev button,
.blog-section-pagitation-next button {
    display: flex;
    align-items: center;  
    justify-content: center;
    background-color: var(--color-primary);
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    margin: 3px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
  
}

.blog-section-pagitation-prev button:hover,
.blog-section-pagitation-next button:hover {
  /* background-color: var(--color-button-hover); */
  filter: brightness(110%);
}

.blog-navigate-all-button {
    background-color: #FFFFFF;
    /* outline: 1px; */
    border: 1px solid var(--color-primary); 
    border-color: var(--color-primary);
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: var(--color-primary);
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content:last baseline;
}

.blog-section-pagitation-prev button[disabled],
.blog-section-pagitation-next button[disabled] {
    /* background-color: #4D4D4D; */
    background-color: #9F9F9F;
    cursor: not-allowed;  
    filter: brightness(100%);
}

.blog-navigate-all-button:hover {
    background-color: var(--color-primary); 
    /* outline: 1px; */
    /* border: 1px solid var(--color-primary);  */
    /* border-color: var(--color-primary); */
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content:last baseline;

}

.blog-section-title {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;    
    margin-bottom: 3rem;
    width: 100%;
}

.blog-section-title   h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
}


.blog-section-cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    
}

.blog-section-pagitation-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;

}




/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {} 

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 576px) {
    .blog-section-cards {
        grid-template-columns: repeat(1, 1fr) !important;
      }

      .blog-section-title h1 {
        font-size: 30px;
        line-height: 42px;
        text-align: center;
        font-weight: 500;
    }

}


@media screen and (max-width: 992px){
    .blog-section-cards {
        grid-template-columns: repeat(2, 1fr);
      }

}