/* .content{
    margin: 1rem;
}    */

.feature-container {
    font-family: var(--font-family);
    color: var(--color-lighttext);
    max-width: 350px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 1px 1px 2px #9F9F9F;
    padding: 1.5rem;
    /* min-height: 300px; */
    min-height: 100%;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: #FFF;
}

.feature-container-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
}

.feature-container-title h1 {
    /* font-family: var(--font-family); */
    font-weight: 800;
    font-size: 18px;
    /* color: var(--color-text); */
    text-align: left;
}

.feature-container-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.feature-container-description {
    display: flex;
    width: 100%;
}

.feature-container-description p {
    /* font-family: var(--font-family); */
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    /* color: var(--color-text); */
}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 576px) {
    .feature-container-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .feature-container-description p {
        font-size: 12px;
        line-height: 20px;
    }

    .feature-container {
        margin: 1rem 0; 
    }
}