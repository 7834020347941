.offered-solutions-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 4rem;
    margin-top: 2rem;
    margin-bottom: 1rem; 
    
}

.offered-solutions-title {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;    
    margin-bottom: 3rem;
    width: 100%;
}

.offered-solutions-title  h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
}

.offered-solutions-cards {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

}

/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 992px){
    .offered-solutions-cards {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    .offered-solutions-container {
        margin-top: 0rem;
        margin-bottom: 0rem;     
    }
   
}

@media screen and (max-width: 576px) {
    .offered-solutions-cards  {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .offered-solutions-title h1 {
        font-weight: 500;
        font-size: 30px;
        text-align: center;

    }


 
}