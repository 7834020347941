.blog-section-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 2rem 4rem;
    margin-top: 2rem;
    background-color: #F5F5F5;
}


.blog-next-prev-buttons{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.blog-navigate-all{
    display: flex;
    justify-content: left;
    margin-bottom: 10px;
}

/* .blog-navigation-buttons {
    position: relative;
    display: flex;
    justify-content:left
  } */

.blog-navigate-next,
.blog-navigate-previous {
    display: flex;
    align-items: center;  
    justify-content: center;
    background-color: var(--color-primary);
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    margin: 3px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
  
}



.blog-navigate-next:hover,
.blog-navigate-previous:hover {
  background-color: var(--color-button-hover);
}

.blog-navigate-all-button {
    background-color: #FFFFFF;
    /* outline: 1px; */
    border: 1px solid var(--color-primary); 
    border-color: var(--color-primary);
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: var(--color-primary);
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content:last baseline;
    margin-right: 50px;
}

.blog-navigate-all-button:hover {
    background-color: var(--color-primary); 
    /* outline: 1px; */
    /* border: 1px solid var(--color-primary);  */
    /* border-color: var(--color-primary); */
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    margin: 3px;
    /* width: 150px;
    height: 50px; */
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    justify-content:last baseline;
    margin-right: 50px;

}




.blog-section-title {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;    
    margin-bottom: 3rem;
    width: 100%;
}

.blog-section-title   h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
}


.blog-section-cards{
    display: flex;
    flex-direction: row;
    /* justify-content: left;
    align-items: center;
    margin-bottom: 10px; */
    
}



/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {} 

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 992px){
  
}


@media screen and (max-width: 768px) {

  
} 



@media screen and (max-width: 576px) {
    .blog-section-container{
        padding: 2rem 4rem;
        width: 100%;
        justify-content: center;
    }

    .blog-section-title h1 {
        font-weight: 500;
        font-size: 30px;
        text-align: center;
    }

}
