.technologies{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem;
    margin-top: 2rem;
    margin-bottom: 1rem; 

}

.technologies h1{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 2rem;

}

.technologies-container{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}


.huggingface-container,
.openai-container,
.aws-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 80px;
    padding: .5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    box-shadow: 1px 1px 3px #888888;
    

}

.huggingface-container img,
.openai-container img,
.aws-container img{
    max-width: 100%;

}

/* .huggingface-container img{
    transform: scale(1.2);
}*/

.aws-container img{
    transform: scale(0.6);
} 




/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/


@media screen and (max-width: 768px){

    .technology-header{
       text-align: center;
    }

    .technologies{
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
}


@media screen and (max-width: 576px){

    .technologies-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    .technology-header h1 {
        font-weight: 500;
        font-size: 30px;
        text-align: center;
    }
}

