.cta-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem;
    margin-top: 2rem;
    margin-bottom: 1rem; 
}

.cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-radius: 1rem;
    background: var(--gradient-bar);
    padding: 2rem 4rem;
}

.cta-title {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.cta-title h2 { 
    font-family: var(--font-family);
    font-weight: 300;
}

.cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.cta-btn button {
    padding: 0.5rem 1rem;
    color: #ffffff;
    background: #170F49;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    border: 1px solid #170F49;;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.cta-btn button:hover {
    background-color: var(--color-button-hover2);
    /* color: #170F49; */
    /* filter: brightness(125%); */
}


/* Breakpoints Points

XX small devices (portrait phones) upto
    @media screen and (max-width: 414px) {}

X small devices (portrait phones) upto
    @media screen and (max-width: 576px) {}

Small devices (landscape phones) upto
    @media screen and (max-width: 768px){}

Medium devices (tablets) upto
    @media screen and (max-width: 992px){}

Large devices (desktops) upto
    @media screen and (max-width: 1200px){}


*/

@media screen and (max-width: 768px) {
    .cta {
        flex-direction: column;
    }

    .cta-btn {
        margin: 2rem 0 0;
    }

    .cta-title h2 {
       text-align: center;
    }

    .cta-container{
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
}

@media screen and (max-width: 576px) {
    .cta {
        flex-direction: column;
        margin: 4rem 2rem;
        
    }

    .cta-title h2 {
        font-size: 18px;
        line-height: 32px;
    }

    .cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}